import React from "react"

import Title from "../Globals/Title"

export default function AboutInfo() {
  return (
    <section className="container pb-2">
      <div className="m-3">
        {/* <div className="my-5 border border-3 rounded-pill border-start-0 border-end-0 border-danger hero-text-xred"> */}
        <div className="my-5 hero-text-xred">
          <Title title="OUR STORY" />
        </div>
        <div className="row">
          <div className="container-sm col-md-10 col-sm-6 col-xs-8 mx-auto text-center">
            <h6 className="hero-text-xred">
              The Crab Station & Oyster Bar in Odessa, Texas est. 2020
            </h6>
            <div className="hero-text-xred ">
              <p className="about-p text-justify">
                Our seafood restaurant is nestled in Odessa, Texas with an
                eclectic selection of seafood and a full service bar! We
                specialize in boiled crustaceans and offer a wide variety of
                seafood: king crab, snow crab, dungeness crab, blue crab, live
                crawfish, raw oysters, shrimp, lobster, and more!
              </p>
              {/* <p className="about-p text-justify">
                For better experience, we toss the seafood in our one of a kind
                cajun garlic butter sauce to compliment the seafood for a tangy,
                spicy, buttery, and sweet taste. If you're feeling a little
                hungry and thirsty because of all the great food, we've got you
                covered! Come join us for a cold beer, a margarita, or one of
                our speciality drinks at our full bar!
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

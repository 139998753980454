import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
// import Helmet from "react-helmet"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import AboutInfo from "../components/About/AboutInfo"
import GalleryImg from "../components/About/GalleryPics"

const AboutPage = ({ data }) => {
  // require("slick-carousel/slick/slick.css")
  // require("slick-carousel/slick/slick-theme.css")
  const { edges: galleryImgData } = data.slideShow
  const image = getImage(data.image.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="About" />
      <BackgroundSection
        img={image}
        title="About Us"
        description="Learn more about us!"
        styleClass="about-background background-images"
      />
      <AboutInfo />
      <GalleryImg img={galleryImgData} />
    </Layout>
  )
}
export const query = graphql`
  {
    image: file(relativePath: { eq: "crab3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3000
          quality: 50
          placeholder: NONE
          blurredOptions: { width: 2000 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    logo: file(relativePath: { eq: "CSlogo.svg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: NONE
          blurredOptions: { width: 100 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    slideShow: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          relativePath
          id
          name
          base
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              height: 900
              width: 900
              placeholder: BLURRED
              quality: 50
              blurredOptions: { width: 100 }
              transformOptions: { cropFocus: CENTER, fit: COVER }
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

export default AboutPage

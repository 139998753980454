import React, { Component } from "react"
import { Carousel } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// SlideShow Component
export default class GalleryPics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "gallery",
      nav1: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
    })
  }
  render() {
    // require("slick-carousel/slick/slick.css")
    const galleryImages = this.props.img
    return (
      <div className="container">
        <div className="row hero-text-xred m-3 menu-info">
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center">
            <Carousel className="slides">
              {galleryImages.map(gallery => {
                const getImages = getImage(
                  gallery.node.childImageSharp.gatsbyImageData
                )
                return (
                  <Carousel.Item
                    key={gallery.node.id}
                    className="text-center "
                    imgStyle={{ objectFit: "contain" }}
                  >
                    <GatsbyImage
                      image={getImages}
                      className=""
                      alt={gallery.node.base.split(".")[0]}
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12 p-4 menu-info-wrapper border border-3 rounded border-start-0 border-end-0 border-bottom-0 border-danger">
            <h1 className="pb-2 my-2">Our Menus</h1>
            <p className="about-p mb-5">
              For better experience, we toss the seafood in our one of a kind
              cajun garlic butter sauce to compliment the seafood for a tangy,
              spicy, buttery, and sweet taste. If you're feeling a little hungry
              and thirsty because of all the great food, we've got you covered!
              Come join us for a cold beer, a margarita, or one of our
              speciality drinks at our full bar!
            </p>
          </div>
        </div>
      </div>
    )
  }
}
